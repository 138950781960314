<template>
  <div v-if="user == null"></div>
  <div v-else class="py-5 px-4 ma-0">
    <v-container v-if="errorMessageBanner && errorMessageBanner.length > 5">
        <v-alert
          type="error"
          dense
          prominent
          color="red darken-4"
          border="left"
        >
        {{ errorMessageBanner }}
        </v-alert>
      </v-container>
    <p>Demo unique search</p>
    <!-- START: contact information -->
    <div class="d-flex justify-center">
      <v-card class="pa-6">
        <v-tabs v-model="currentTab">
          <v-tab>
            <v-icon class="mr-1">mdi-link</v-icon>
            <span>Find data by LinkedIn URL</span>
          </v-tab>
          <v-tab>
            <v-icon class="mr-1">mdi-account</v-icon>
            <span>Find person</span>
          </v-tab>
          <v-tab>
            <v-icon class="mr-1">mdi-email</v-icon>
            <span>Email lookup</span>
          </v-tab>
          <v-tab>
            <v-icon class="mr-1">mdi-domain</v-icon>
            <span>Find company</span>
          </v-tab>

          <v-tabs-items v-model="currentTab" touchless class="pt-4 pb-10 px-1">
            <v-tab-item>
              <v-form class="d-flex align-center gap" onsubmit="return false" ref="form" v-model="valid">
                <input @keypress.enter="findByLinkedinUrl()" type="text" placeholder="Enter a LinkedIn link… (e.g.: https://www.linkedin.com/in/williamhgates/)" v-model="linkedInUrl"/>
                <v-btn @click="findByLinkedinUrl()" :disabled="loading || !valid" class="btn-visum" color="primary" depressed>Search</v-btn>
              </v-form>
              <div v-if="linkedInUrl.includes('/in/')" class="d-flex justify-center align-center at mt-10 pa-5">GET {{vue_app_api_url}}enrichment/profile?apikey=YOUR_API_KEY&linkedinUrl={{linkedInUrl}}</div>
              <div v-else-if="linkedInUrl.includes('/company/')" class="d-flex justify-center align-center at mt-10 pa-5">GET {{vue_app_api_url}}enrichment/company?apikey=YOUR_API_KEY&linkedinUrl={{linkedInUrl}}</div>
            </v-tab-item>
            <v-tab-item>
              <v-form class="d-flex align-center gap" onsubmit="return false" ref="form" v-model="valid">
                <input @keypress.enter="findLead()" type="text" placeholder="Enter a first name…" v-model="firstName" />
                <input @keypress.enter="findLead()" type="text" placeholder="Enter a last name…" v-model="lastName" />
                <input @keypress.enter="findLead()" type="text" placeholder="company.com" v-model="companyDomain" />
                <v-btn @click="findLead()" :disabled="loading || !valid" class="btn-visum" color="primary" depressed>Search</v-btn>
              </v-form>
              <div class="d-flex justify-center align-center at mt-10 pa-5">GET {{vue_app_api_url}}enrichment?apikey=YOUR_API_KEY&firstName={{firstName}}&lastName={{lastName}}&companyDomain={{companyDomain}}</div>
            </v-tab-item>
            <v-tab-item>
              <v-form class="d-flex align-center gap" onsubmit="return false" ref="form" v-model="valid">
                <input @keypress.enter="emailLookup()" type="email" placeholder="Enter an email address… (e.g.: john.doe@company.com)" v-model="email" />
                <v-btn @click="emailLookup()" :disabled="loading || !valid" class="btn-visum" color="primary" depressed>Search</v-btn>
              </v-form>
              <div class="d-flex justify-center align-center at mt-10 pa-5">GET {{vue_app_api_url}}enrichment?apikey=YOUR_API_KEY&email={{email}}</div>
            </v-tab-item>
            <v-tab-item>
              <v-form class="d-flex align-center gap" onsubmit="return false" ref="form" v-model="valid">
                <input @keypress.enter="domainLookup()" type="text" placeholder="Enter a company domain… (e.g.: company.com)" v-model="companyDomain"/>
                <v-btn @click="domainLookup()" :disabled="loading || !valid" class="btn-visum" color="primary" depressed>Search</v-btn>
              </v-form>
              <div class="d-flex justify-center align-center at mt-10 pa-5">GET {{vue_app_api_url}}enrichment/company/domain?apikey=YOUR_API_KEY&domain={{companyDomain}}</div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <!-- Loading annimation -->
        <v-card v-if="loading" class="search-card ma-6">
          <v-container>
            <lottie-animation
              ref="anim"
              :animationData="
                require('@/assets/lotties/ScrapinAnimation.json')
              "
              :loop="true"
              :autoPlay="true"
              :speed="1"
              style="width: 100px; margin-left: auto; margin-right: auto"
            />
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col class="text-subtitle-1 text-center" cols="12">
                <h2>Searching... 🦉</h2>
                <span class="text-caption my-2">
                  Our enrichment complies with GDPR and CCPA laws, we don't have any databases, we retrieve all data with OSINT publicy available methods.
                </span>
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  :color="$theme.secondary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
          <v-skeleton-loader
            v-if="loading"
            type="article, actions"
          ></v-skeleton-loader>
        </v-card>

      <!-- Person & company preview inforation card -->
      <v-row class="ma-6">
        <v-col
          cols="6"
          v-if="responseData.person != null && responseData.person != undefined"
        >
          <v-card class="pa-6 rounded-xxl user">
            <div class="d-flex flex-column align-center user-personal mb-8">
              <v-img
                v-if="responseData.person.photoUrl != null"
                size="92"
                style="border-radius: 50%"
                :src="responseData.person.photoUrl"
                class="user-personal-image"
                width="100"
                height="100"
              />

              <h2
                v-if="
                  responseData.person.firstName != null &&
                  responseData.person.lastName != null
                "
                class="text-h5"
              >
                {{ responseData.person.firstName }}
                {{ responseData.person.lastName }}
              </h2>
              <p
                v-if="responseData.person.positions != null"
                class="text-h7 my-2 d-table-cell font-weight-bold"
              >
                {{
                  responseData.person.positions.positionsCount > 0
                    ? responseData.person.positions.positionHistory[0].title
                    : responseData.person.headline
                }}
              </p>
              <p v-if="responseData.email" class="text-h7 my-2">
                {{ responseData.email }}
                <span class="text-caption my-2">
                  ({{ responseData.emailType }})
                </span>
              </p>
            </div>

            <v-row>
              <v-col
                v-if="responseData.person.location != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">Location</h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/location.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.person.location
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.person.followerCount != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  LinkedIn Followers Count
                </h3>
                <div class="d-flex align-center mt-2 size-20">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/linkedin.svg`)"
                      width="20"
                      height="20"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.person.followerCount
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Name
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/business.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">
                    {{ responseData.company.name }}
                  </span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Tagline
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/editor.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.tagline
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Website
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/attach-file.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.websiteUrl
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Headquarter
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/meeting-room.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold"
                    >{{ responseData.company.headquarter.country }},
                    {{ responseData.company.headquarter.geographicArea }},
                    {{ responseData.company.headquarter.city }},
                    {{ responseData.company.headquarter.postalCode }}</span
                  >
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Industry
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/factory.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.industry
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company # Employees
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/user-group.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.employeeCount
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>
            </v-row>

            <a
              v-if="responseData.person.linkedInUrl != null"
              target="_blank"
              :href="responseData.person.linkedInUrl"
            >
              <v-btn
                class="font-weight-semibold text-body-1 mt-8 signup-button"
                color="primary"
                rounded
                block
              >
                <v-icon left> mdi-linkedin </v-icon>
                See on LinkedIn
              </v-btn>
            </a>
          </v-card>
        </v-col>

        <v-col
          cols="6"
          v-if="
            responseData.company != null && responseData.person == undefined
          "
        >
          <v-card class="pa-6 rounded-xxl user">
            <div class="d-flex flex-column align-center user-personal mb-8">
              <v-img
                v-if="responseData.company.logo != null"
                size="92"
                style="border-radius: 50%"
                :src="responseData.company.logo"
                class="user-personal-image"
                width="100"
                height="100"
              />
            </div>

            <v-row>
              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Name
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/business.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">
                    {{ responseData.company.name }}
                  </span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Tagline
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/editor.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span
                    v-if="responseData.tagline != null"
                    class="secondary--text font-weight-bold"
                    >{{ responseData.company.tagline }}</span
                  >
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company.websiteUrl != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Website
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/attach-file.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.websiteUrl
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company.headquarter != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Headquarter
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/meeting-room.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold"
                    >{{ responseData.company.headquarter.country }},
                    {{ responseData.company.headquarter.geographicArea }},
                    {{ responseData.company.headquarter.city }},
                    {{ responseData.company.headquarter.postalCode }}</span
                  >
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company.industry != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company Industry
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/factory.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.industry
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>

              <v-col
                v-if="responseData.company.employeeCount != null"
                :cols="6"
                class="user-data"
              >
                <h3 class="text-body-2 gray--text text-uppercase">
                  Company # Employees
                </h3>
                <div class="d-flex align-center mt-2">
                  <div class="icon mr-2">
                    <v-img
                      :src="require(`@/assets/icons/user-group.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="secondary--text font-weight-bold">{{
                    responseData.company.employeeCount
                  }}</span>
                </div>
                <hr class="ma-0 mt-2 user-data-divider" />
              </v-col>
            </v-row>

            <a
              v-if="responseData.company.linkedInUrl != null"
              target="_blank"
              :href="responseData.company.linkedInUrl"
            >
              <v-btn
                class="font-weight-semibold text-body-1 mt-8 signup-button"
                color="primary"
                rounded
                block
              >
                <v-icon left> mdi-linkedin </v-icon>
                See on LinkedIn
              </v-btn>
            </a>
          </v-card>
        </v-col>

        <!-- API JSON preview information profile -->
        <v-col cols="6" v-if="responseData.person != null">
          <v-card class="search-card">
            <v-card-title>API Response Data</v-card-title>
            <div justify="center" v-if="responseData.person != null">
              <json-viewer copyable :value="responseData"></json-viewer>
            </div>
          </v-card>
        </v-col>

        <!-- API JSON preview information company -->
        <v-col
          cols="6"
          v-if="
            responseData.company != null && responseData.person == undefined
          "
        >
          <v-card class="search-card">
            <v-card-title>API Response Data</v-card-title>
            <div justify="center" v-if="responseData.company != null">
              <json-viewer copyable :value="responseData"></json-viewer>
            </div>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </div>

</template>

<script>
import axios from "axios";
import OSINTServices from "@/services/OSINTServices";
import LottieAnimation from "lottie-web-vue";
import Swal from "sweetalert2";
import JsonViewer from 'vue-json-viewer';
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    LottieAnimation,
    JsonViewer
  },
  data() {
    return {
      errorMessageBanner: null,
      loading: false,
      valid: false,
      advancedSearch: false,
      email: "",
      firstName: "",
      lastName: "",
      companyDomain: "",
      linkedInUrl: "",
      responseData: {},
      emailRules: [
        (v) => !!v || "An email is required",
        (v) => /.+@.+\..+/.test(v) || "The e-mail must be valid",
      ],
      currentTab: 0
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetchErrorMessage()
    this.vue_app_api_url = process.env.VUE_APP_API_URL
  },
  methods: {
    async fetchErrorMessage() {
      const url = 'https://raw.githubusercontent.com/visum-run/notification/main/reverse_contact_error.txt' + '?timestamp=' + new Date().getTime()

        axios.get(url)
        .then(response => {
            this.errorMessageBanner = response.data;

        })
        .catch(error => {
            console.log(error);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    async findLead() {
      this.email = this.firstName.toLowerCase() + "." + this.lastName.toLowerCase() + "@" + this.companyDomain.toLowerCase()
      this.emailLookup()
    },
    async domainLookup() {
      try {
        this.loading = true;
        this.responseData = {};
        var [linkedinProfile, linkedinProfileError] =
          await OSINTServices.domainLookup({
            companyDomain: this.companyDomain,
            apikey: this.user.api.secretKey
          });

        if (linkedinProfileError) throw linkedinProfileError;

        switch (true) {
          case !linkedinProfile.data.company:
              Swal.fire({
                icon: "warning",
                title: "No data found",
                text: "Sorry, we didn't find any data on this.",
                footer: 'To comply with GDPR and CCPA laws, we do not maintain any databases. Instead, we retrieve all data using OSINT (Open Source Intelligence) methods, which are publicly available. This approach is why there are instances when we may be unable to find data associated on this.'
              })
            break;
          case linkedinProfile.data.company != null:
            this.responseData = linkedinProfile.data;
            this.responseData.person = null
            break;
          default:
            Swal.fire({
              icon: "error",
              title: linkedinProfile.data.title,
              text: linkedinProfile.data.msg,
            });
        }

        this.$root.$emit(
          "update_progress_bar",
          linkedinProfile.data.credits_left
        );
        this.$root.$emit(
          "update_progress_bar_rate_limit",
          this.user.quota.rate_limit + 1
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        if (error.response.status == 402) {
          Swal.fire({
            icon: "warning",
            title: "Insufficient credits",
            text: "That's it for today! But you can choose to upgrade your plan to get more credits!",
          })
          this.$router.push({ name: "Pricing" })
        } else {
          Swal.fire({
            icon: "error",
            title: error.response.data.title,
            text: error.response.data.msg,
          })
        }
      }
    },
    async emailLookup() {
      try {
        this.loading = true;
        this.responseData = {};
        var [linkedinProfile, linkedinProfileError] =
          await OSINTServices.emailLookup({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            companyDomain: this.companyDomain
          });
        if (linkedinProfileError) throw linkedinProfileError;

        switch (true) {
          case !linkedinProfile.data.person && !linkedinProfile.data.company:
            if (this.currentTab == 0) {
              Swal.fire({
                icon: "warning",
                title: "No data found",
                text: "Sorry, we didn't find any data on this email. You can try again on 'Find Person' lookup",
                confirmButtonText: 'Try "Find Person" lookup',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.currentTab = 1
                  Swal.fire({
                    icon: "success",
                    title: "Advanced search activated",
                    text: "You can now input more data to improve the match rate.",
                    showConfirmButton: false,
                    timer: 4000,
                  });
                }
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: "No data found",
                text: "Sorry, we didn't find any data on this email.",
                footer: 'To comply with GDPR and CCPA laws, we do not maintain any databases. Instead, we retrieve all data using OSINT (Open Source Intelligence) methods, which are publicly available. This approach is why there are instances when we may be unable to find data associated with a particular email.'
              })
            }
            break;
          case !linkedinProfile.data.person && linkedinProfile.data.company != null:
            this.responseData = linkedinProfile.data;
            Swal.fire({
              icon: "info",
              title: "Only company data found",
              text: "We didn't find any information about the owner of the email, but we were able to find information about the company through its domain name.",
            });
            break;
          case linkedinProfile.data.success:
            this.responseData = linkedinProfile.data;
            break;
          default:
            Swal.fire({
              icon: "error",
              title: linkedinProfile.data.title,
              text: linkedinProfile.data.msg,
            });
        }

        this.$root.$emit(
          "update_progress_bar",
          linkedinProfile.data.credits_left
        );
        this.$root.$emit(
          "update_progress_bar_rate_limit",
          this.user.quota.rate_limit + 1
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response.status == 402) {
          Swal.fire({
            icon: "warning",
            title: "Insufficient credits",
            text: "That's it for today! But you can choose to upgrade your plan to get more credits!",
          })
          this.$router.push({ name: "Pricing" })
        } else {
          Swal.fire({
            icon: "error",
            title: error.response.data.title,
            text: error.response.data.msg,
          })
        }
      }
    },
    async findByLinkedinUrl() {
      try {
        this.loading = true;
        this.responseData = {};
        if (this.linkedInUrl.includes("/company/")) {
          var [linkedinProfile, linkedinProfileError] =
            await OSINTServices.ExtractCompanyDataProfile({
              linkedInUrl: this.linkedInUrl,
              apikey: this.user.api.secretKey
            });
        } else {
          console.log(this.linkedInUrl);
          var [linkedinProfile, linkedinProfileError] =
            await OSINTServices.ExtractPersonDataProfile({
              linkedInUrl: this.linkedInUrl,
              apikey: this.user.api.secretKey
            });
        }
        if (linkedinProfileError) throw linkedinProfileError;

        switch (true) {
          case !linkedinProfile.data.person && !linkedinProfile.data.company:
            Swal.fire({
              icon: "warning",
              title: "No data found",
              text: "Sorry, we didn't find any data on this link.",
              footer: 'To comply with GDPR and CCPA laws, we do not maintain any databases. Instead, we retrieve all data using OSINT (Open Source Intelligence) methods, which are publicly available. This approach is why there are instances when we may be unable to find data associated with a particular email.'
            })
            break;
          case !linkedinProfile.data.person && linkedinProfile.data.company != null:
            this.responseData = linkedinProfile.data;
            this.responseData.person = null
            break;
          case linkedinProfile.data.success:
            this.responseData = linkedinProfile.data;
            break;
          default:
            Swal.fire({
              icon: "error",
              title: linkedinProfile.data.title,
              text: linkedinProfile.data.msg,
            });
        }

        this.$root.$emit(
          "update_progress_bar",
          linkedinProfile.data.credits_left
        );
        this.$root.$emit(
          "update_progress_bar_rate_limit",
          this.user.quota.rate_limit + 1
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response.status == 402) {
          Swal.fire({
            icon: "warning",
            title: "Insufficient credits",
            text: "That's it for today! But you can choose to upgrade your plan to get more credits!",
          })
          this.$router.push({ name: "Pricing" })
        } else {
          Swal.fire({
            icon: "error",
            title: error.response.data.title,
            text: error.response.data.msg,
          })
        }
      }
    },
  },
};
</script>

<style lang="scss">
.search-card {
  padding: 16px 24px;
  margin: 0 0 20px 0 !important;
  width: 100%;
  border-radius: 8px !important;
}

.dns-tool {
  /*box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);*/
  background-color: #f5f5f5;
  overflow: visible;
  border-radius: 8px;
  padding: 12px 20px;
  margin-right: 30px;
  font-weight: 500;
}

.dns-tool > img {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 12px !important;
  border-radius: 4px !important;
  width: auto;
  height: 30px;
}

.v-card__title {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0;
  padding-top: 0;
}

.tool-statistics {
  display: block;
  font-size: 0.83em;
  text-transform: uppercase;
  font-weight: 500;
}

.tool-logo {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.v-tabs--icons-and-text > .v-tabs-bar .v-tab {
  flex-direction: row-reverse;
}

.user {
  max-width: 620px !important;

  &-personal {
    &-image {
      margin-bottom: 6px !important;
    }

    &-name,
    &-role {
      margin-bottom: 2px !important;
    }
  }

  &-data-divider {
    border: none;
    border-top: 1px solid #eaeaea !important;
  }
}

.icon {
  width: 24px;
  height: 24px;

  &.size-20 {
    width: 20px;
    height: 20px;
  }
}

.signup-button {
  height: 54px !important;
  box-shadow: 0px 8px 16px 0px #2873ea3d !important;
}

.popup-card {
  position: relative;

  .popup-close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  @media screen and (min-width: 601px) and (max-width: 959px) {
    iframe {
      max-width: 600px;
      width: 100%;
      height: 350px;
    }
  }

  @media screen and (min-width: 501px) and (max-width: 600px) {
    iframe {
      width: 100%;
      height: 250px;
    }
  }

  @media screen and (max-width: 500px) {
    iframe {
      width: 100%;
      height: 200px;
    }
  }
}

.logo {
  width: 80px;
  height: 80px;
}
</style>

<style scoped>
.gap {
  gap: 8px;
}

.at {
  height: 42px;
  min-width: 42px;
  background: rgba(229, 229, 229, 0.292);
  border: 1px solid gray;
  border-radius: 6px;
  color: grey;
  pointer-events: none;
}
</style>
